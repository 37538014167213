@import url("https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css");
@import 'css/icons.css';
@import 'css/easy-responsive-tabs.css';
@import 'css/flexslider.css';
@import 'css/owl.carousel.css';
@import 'css/style.css';
@import 'css/ie7/ie7.css';

#whatsapp-icon {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: green; /* Change the background color as needed */
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    transition: background-color 0.3s ease, transform 0.3s ease,
      box-shadow 0.3s ease; /* Add transitions for background-color, transform, and box-shadow */
  }
  
  #whatsapp-icon:hover {
    background-color: rgb(9, 231, 9);
    transform: scale(1.1); /* Add a scaling effect on hover */
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); /* Add a shadow effect on hover */
  }
  
  #whatsapp-icon img {
    width: 30px; /* Adjust the icon size as needed */
    height: 30px;
    border-radius: 50%;
  }

  
  .partners .partner-slider .partner-slider-row .partner-logo {
    display: table;
    width: 100%;
    height: 84px;
    text-align: center;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
  }
  .partners .partner-slider .partner-slider-row .partner-logo p {
    display: table-cell;
    vertical-align: middle;
    max-height: 44px;
  }
  .partners .partner-slider .partner-slider-row .partner-logo p img{
    width: auto;
    margin: auto;
  }


  .product-list-item:hover {
    cursor: pointer;
  }