.top-bar {
  background-color: #304254;
  line-height: 50px;
  left: 0;
  top: 0;
  width: 100%;
  position: fixed;
}
.address {
  color: #6f8aa6;
  font-size: 13px;
}
.address i {
  font-size: 18px;
  vertical-align: middle;
}
.social ul {
  float: right;
}
.social li {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}
.social li a {
  displat: inline-block;
  font-size: 16px;
  color: #4e6073;
}
.social li a:hover {
  color: #00b2ce;
}
.navbar-custom {
  background: #344659;
  top: 50px;
  padding: 15px 0;
}
.top-nav-collapse {
  margin: 0;
}
.navbar-collapse {
  float: right;
}
.navbar-custom .navbar-brand {
  color: #fff;
  font-weight: 800;
  font-size: 30px;
}
.navbar-custom .navbar-brand:hover {
  color: #fff;
}
.navbar-custom .navbar-brand span {
  font-weight: 300;
  color: #00b2ce;
}
.navbar-custom .navbar-brand span:hover {
  color: #00b2ce;
}
.navbar-custom .navbar-brand:focus {
  outline: 0;
}
.navbar-header p {
  border-left: 1px solid #465a6f;
  padding-left: 15px;
  float: left;
  color: #cddae7;
  font-size: 12px;
  line-height: 20px;
}
.navbar-header p b {
  font-size: 16px;
  font-weight: 400;
  display: block;
}
.navbar-custom .navbar-toggle {
  font-size: 28px;
  color: #00b2ce;
  margin: 0;
  padding: 5px 10px 0;
}
.navbar-custom .navbar-toggle:active,
.navbar-custom .navbar-toggle:focus {
  outline: 0;
}
.navbar-custom a {
  color: #98acc0;
  background-color: transparent;
}
.navbar-custom a:hover {
  color: #00b2ce;
}
.navbar-custom .nav li a {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  border: 1px solid #344659;
  line-height: 28px;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  padding: 10px 25px;
}
.navbar-custom .nav li a:focus,
.navbar-custom .nav li a:hover {
  outline: 0;
  color: #00b2ce;
  border-color: #00b2ce;
  background-color: transparent;
}
.navbar-custom .nav li a.btn-default {
  background-color: #00b2ce;
  line-height: 50px;
  color: #fff;
  text-transform: uppercase;
  border: none;
  font-size: 13px;
  font-weight: 600;
  padding: 0 40px;
  margin: 0 10px;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.15);
}
.navbar-custom .nav li a.btn-default:active,
.navbar-custom .nav li a.btn-default:focus,
.navbar-custom .nav li a.btn-default:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}
.navbar-custom .nav li.dropdown ul.dropdown-menu {
  background-color: #293949;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  left: 0;
  right: auto;
  top: 132%;
}
.navbar-custom .nav li.dropdown ul.dropdown-menu li {
  border-bottom: 1px solid #394b5f;
}
.navbar-custom .nav li.dropdown ul.dropdown-menu li a {
  line-height: 30px;
  border: none;
  color: #98acc0;
  font-size: 13px;
}
.navbar-custom .nav li.dropdown ul.dropdown-menu li a:focus,
.navbar-custom .nav li.dropdown ul.dropdown-menu li a:hover {
  color: #fff;
  border: none;
  outline: 0;
  background-color: transparent;
}
.navbar-custom .nav li.dropdown ul.dropdown-menu li:last-child {
  border: none;
}
.navbar-custom .nav li.open a {
  background-color: transparent;
}
.navbar-custom .nav li.open a:focus,
.navbar-custom .nav li.open a:hover {
  outline: 0;
  background-color: transparent;
}
.breadcrumb {
  background-color: #f3f6f8;
  padding: 40px 0;
}
.breadcrumb h2 {
  color: #304254;
  font-weight: 300;
  font-size: 30px;
  margin: 0;
}
.breadcrumb ul {
  margin-top: 15px;
}
.breadcrumb ul li {
  color: #00b2ce;
  font-size: 14px;
  display: inline-block;
}
.breadcrumb ul li a {
  color: #00b2ce;
  display: inline-block;
  padding: 0 5px;
}
.breadcrumb ul li:last-child a {
  color: #989898;
}
@media (min-width: 768px) {
  .navbar-custom {
    -webkit-transition: background 0.5s ease-in-out, padding 0.3s ease-in-out;
    -moz-transition: background 0.5s ease-in-out, padding 0.3s ease-in-out;
    -o-transition: background 0.5s ease-in-out, padding 0.3s ease-in-out;
    transition: background 0.5s ease-in-out, padding 0.3s ease-in-out;
  }
  .navbar-custom.top-nav-collapse {
    padding: 10px 0;
    top: 0;
  }
  .navbar-custom.top-nav-collapse .nav li.dropdown ul.dropdown-menu {
    top: 122%;
  }
}
@media (min-width: 981px) and (max-width: 1169px) {
  .navbar-custom .nav {
    margin-top: 15px;
  }
  .navbar-custom .nav li a {
    padding: 10px 22px;
  }
  .navbar-custom .nav li.dropdown ul.dropdown-menu {
    top: 165%;
  }
  .navbar-custom.top-nav-collapse .nav li.dropdown ul.dropdown-menu {
    top: 155%;
  }
}
@media (min-width: 768px) and (max-width: 1169px) {
  .navbar-header p {
    border-left: none;
    float: none;
    clear: both;
    padding: 0;
  }
  .navbar-header p b {
    display: inline-block;
  }
}
@media (min-width: 768px) and (max-width: 980px) {
  .navbar-header {
    width: 100%;
  }
  .navbar-toggle {
    display: block !important;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-collapse.in {
    display: block !important;
  }
  .navbar-custom .nav {
    float: none;
    text-align: left;
    margin: 0;
    width: 100%;
    border-top: 1px solid #465a6f;
  }
  .navbar-custom .nav li {
    float: none;
    display: block;
  }
  .navbar-custom .nav li a {
    border: none;
  }
  .navbar-collapse {
    margin-top: 0;
    padding: 0;
    float: none;
  }
}
@media (max-width: 767px) {
  .top-bar {
    line-height: 35px;
    text-align: center;
    position: relative;
  }
  .social ul {
    float: none;
  }
  .navbar-custom {
    top: 72px;
  }
  .top-nav-collapse {
    top: 0;
  }
  .navbar-custom .nav {
    float: none;
    text-align: left;
    margin: 0;
  }
  .navbar-custom .nav li {
    float: none;
    display: block;
  }
  .navbar-custom .nav li a {
    border: none;
  }
  .navbar-collapse {
    margin-top: 0;
    padding: 0;
    float: none;
  }
  .address {
    font-size: 11px;
  }
  .navbar-header p {
    display: none;
  }
}
footer {
  background-color: #344659;
  padding-top: 40px;
  margin-top: 60px;
  color: #768ba2;
}
footer h2 {
  color: #fff;
  font-weight: 800;
  font-size: 30px;
  margin: 25px 0;
}
footer h2 span {
  font-weight: 300;
  color: #00b2ce;
}
footer a {
  color: #768ba2;
}
footer a:hover {
  color: #00b2ce;
}
.contact-sec {
  border-bottom: 1px solid #455a70;
  padding-bottom: 50px;
  background: url(../images/footer-line.jpg) repeat-y center top;
}
.contact-sec p {
  font-size: 14px;
  font-weight: 300;
  color: #768ba2;
  line-height: 24px;
}
.contact-sec li {
  line-height: 30px;
}
.contact-sec li i {
  margin-right: 10px;
  font-size: 16px;
}
.contact-sec .btn-default {
  margin-top: 20px;
}
.footer-nav {
  padding-top: 40px;
}
.footer-nav li {
  display: inline-block;
  margin-right: 35px;
  font-size: 14px;
}
.footer-social {
  padding-top: 40px;
}
.footer-social li {
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
}
.copyright {
  margin-top: 40px;
  padding: 20px 0;
  background-color: #304254;
}
.copyright .text-right a {
  margin-left: 40px;
}
@media (min-width: 981px) and (max-width: 1169px) {
  .footer-nav li {
    margin-right: 17px;
  }
  .footer-social li {
    margin-left: 10px;
  }
}
@media (min-width: 768px) and (max-width: 980px) {
  .contact-sec {
    background: none;
  }
  .footer-nav li {
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .contact-sec {
    background: none;
  }
  .footer-nav li {
    margin: 0;
    width: 49%;
    padding: 10px 0;
  }
  .copyright {
    line-height: 35px;
  }
  .copyright div {
    text-align: center !important;
  }
  .copyright a {
    margin: 0 5px !important;
  }
}
body {
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  color: #000;
  background-color: #fff;
}
html {
  width: 100%;
  height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
a {
  text-decoration: none;
  transition: color 0.3s ease;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
}
a:focus,
a:hover {
  text-decoration: none;
  background-color: transparent;
}
input[type="password"],
input[type="text"],
select,
textarea {
  border: none;
  width: 100%;
  display: block;
  margin-bottom: 20px;
  height: 40px;
  line-hegiht: 40px;
  padding: 0;
  border-bottom: 1px solid #e9e9e9;
  font-weight: 300;
  color: #989898;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
input[type="password"]:focus,
input[type="text"]:focus,
select:focus,
textarea:focus {
  border-color: #ccc;
}
textarea {
  min-height: 100px;
}
button,
input[type="submit"] {
  border: none;
  cursor: pointer;
}
body {
  webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
}
li,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.left {
  float: left;
}
.right {
  float: right;
}
.clear {
  clear: both;
  float: none;
}
.ml-0 {
  margin-left: 0px !important;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.vi_mid {
  vertical-align: middle;
}
.btn-default {
  background-color: #00b2ce;
  line-height: 50px;
  height: 50px;
  color: #fff;
  text-transform: uppercase;
  border: none;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  padding: 0 40px;
  margin: 0;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.15);
  display: inline-block;
}
.btn-default:active,
.btn-default:focus,
.btn-default:hover {
  color: #fff;
  background-color: #00b2ce;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}
.btn-secondary {
  background-color: #a6a6a6;
  line-height: 40px;
  height: 40px;
  color: #fff;
  text-transform: uppercase;
  border: none;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  padding: 0 30px;
  margin: 0;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.15);
  display: inline-block;
}
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
  color: #fff;
  background-color: #00b2ce;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}
#page-content {
  margin-top: 130px;
}
#page-content h2 {
  font-weight: 300;
  font-size: 30px;
  color: #304254;
}
@media (min-width: 768px) and (max-width: 1169px) {
  #page-content {
    margin-top: 163px;
  }
}
@media (max-width: 767px) {
  #page-content {
    margin-top: 81px;
  }
}
.flexslider .slide-info {
  vertical-align: middle;
  text-align: center;
  position: absolute;
  left: 15%;
  top: 18%;
  background-color: #00b2ce;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  width: 397px;
  height: 397px;
}
.flexslider .slide-con {
  padding: 100px 40px 0;
  position: relative;
}
.flexslider .slide-con b {
  display: inline-block;
  padding: 0 20px;
  background-color: #0098b0;
  background-color: rgba(0, 0, 0, 0.1);
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  color: #1ee0ff;
}
.flexslider .slide-con h3 {
  font-weight: 800;
  color: #fff;
  margin-top: 20px;
  font-size: 32px;
}
.flexslider .slide-con p {
  font-size: 16px;
  margin-top: 15px;
  line-height: 27px;
  color: #c7f6fd;
}
.flexslider .slide-con a {
  position: absolute;
  right: 80px;
  bottom: -120px;
  background-color: #333;
  color: #fff;
  font-size: 24px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  padding-top: 15px;
  width: 60px;
  text-align: center;
  height: 60px;
}
@media (min-width: 981px) and (max-width: 1169px) {
  .flexslider .slide-info {
    top: 10%;
    left: 10%;
    width: 350px;
    height: 350px;
  }
  .flexslider .slide-con {
    padding: 70px 30px 0;
  }
  .flexslider .slide-con a {
    right: 90px;
    bottom: -85px;
    width: 50px;
    height: 50px;
  }
}
@media (min-width: 768px) and (max-width: 980px) {
  .flexslider .slide-info {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
  }
  .flexslider .slide-con {
    padding: 25px 0;
  }
  .flexslider .slide-con p {
    padding: 0 25px;
  }
  .flexslider .slide-con a {
    position: relative;
    right: 0px;
    bottom: 0px;
    display: inline-block;
    text-align: center;
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 767px) {
  .flexslider .slide-info {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
  }
  .flexslider .slide-con {
    padding: 25px 0;
  }
  .flexslider .slide-con p {
    padding: 0 25px;
    font-size: 14px;
    line-height: 22px;
  }
  .flexslider .slide-con h3 {
    font-size: 24px;
  }
  .flexslider .slide-con a {
    position: relative;
    right: 0px;
    bottom: 0px;
    display: inline-block;
    text-align: center;
    width: 50px;
    height: 50px;
  }
}
.intro {
  margin: 70px 0;
}
.intro p {
  margin-top: 15px;
  line-height: 30px;
  color: #989898;
  font-size: 14px;
  font-weight: 300;
}
.intro ul {
  margin-top: 50px;
}
.intro ul li p {
  line-height: 25px;
}
.intro h3 {
  color: #304254;
  font-size: 16px;
  margin-top: 15px;
}
.intro i {
  display: inline-block;
  background-color: #00b2ce;
  color: #fff;
  font-size: 20px;
  padding: 15px 10px;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.get-quote-form {
  position: relative;
  margin-top: -65px;
  margin-left: -15px;
  z-index: 10;
  box-shadow: 0 -14px 30px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 -14px 30px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 -14px 30px 0 rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 -14px 30px 0 rgba(0, 0, 0, 0.05);
}
.get-quote-form h2 {
  color: #fff !important;
  line-height: 65px;
  background-color: #00b2ce;
  padding: 0 25px;
  margin: 0;
  font-size: 24px !important;
}
.get-quote-form form {
  border: 1px solid #f3f3f3;
  border-top: none;
  border-bottom: none;
  background-color: #fff;
  padding: 0 25px;
}
.get-quote-form form div {
  padding-top: 25px;
}
.get-quote-form form input[type="text"],
.get-quote-form form select,
.get-quote-form form textarea {
  margin: 0;
  padding: 0;
  border: none;
  border-bottom: 1px solid #e9e9e9;
  font-weight: 300;
  text-indent: 10px;
  line-height: 40px;
  height: 40px;
  color: #989898;
  font-size: 14px;
  width: 100%;
}
.get-quote-form form .form-select {
  overflow: hidden;
  position: relative;
}
.get-quote-form form .form-select span {
  position: absolute;
  right: 15px;
  top: 35px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #d3d3d3;
}
.get-quote-form form select {
  width: 107%;
  text-indent: 0;
}
.get-quote-form form textarea {
  font-family: "Open Sans", sans-serif;
  height: 120px;
}
.get-quote-form form .text-center {
  padding: 50px 0;
}
@media (max-width: 980px) {
  .get-quote-form {
    margin-top: 65px;
    margin-left: 0;
  }
}
.product-tab {
  background-color: #f3f6f8;
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.product-tab h2 {
  margin-top: 50px;
  margin-bottom: 30px;
}
.product-tab h4 {
  font-size: 24px;
  color: #304254;
  font-weight: 300;
  margin: 0;
}
.product-tab h4 span {
  background-color: #00b2ce;
  margin-right: 15px;
  margin-top: -25px;
  text-align: center;
  width: 50px;
  display: inline-block;
  padding: 50px 0 20px;
  border-radius: 0 0 25px 25px;
  -moz-border-radius: 0 0 25px 25px;
  -webkit-border-radius: 0 0 25px 25px;
  color: #fff;
  font-size: 24px;
}
.product-tab p {
  font-weight: 300;
  font-size: 14px;
  color: #869294;
  line-height: 30px;
  margin-top: 30px;
}
.product-tab p.tel {
  font-size: 20px;
}
.product-tab p.tel span {
  font-size: 12px;
  display: block;
}
.product-tab img {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  max-width: 750px;
  width: 100%;
}
.resp-arrow {
  border-top-color: #00b2ce;
}
h3.resp-tab-active span.resp-arrow {
  border-bottom-color: #00b2ce;
}
@media (min-width: 1920px) {
  .product-tab img {
    max-width: 775px;
  }
}
@media (min-width: 768px) and (max-width: 980px) {
  .product-tab h4 span {
    margin-top: 0;
  }
  .product-tab .prod-tab-content {
    display: inline-block;
    width: 60%;
  }
  .product-tab img {
    position: relative;
    z-index: 1;
    margin-top: 30px;
    float: right;
    width: 35%;
  }
}
@media (max-width: 767px) {
  .product-tab h4 span {
    margin-top: 0;
  }
  .product-tab .prod-tab-content {
    display: inline-block;
    width: 100%;
  }
  .product-tab img {
    position: relative;
    z-index: 1;
    margin-top: 20px;
    width: 100%;
  }
}
.services {
  margin: 70px 0;
}
.services .services-dtl {
  margin-top: 60px;
  min-height: 155px;
}
.services .services-dtl span {
  color: #00b2ce;
  font-size: 30px;
  line-height: normal;
}
.services .services-dtl h3 {
  font-weight: 400;
  color: #304254;
  font-size: 16px;
  margin-bottom: 0;
}
.services .services-dtl p {
  color: #989898;
  font-size: 14px;
  line-height: 25px;
  margin-top: 15px;
}
.testimonials {
  padding: 70px 0;
  background-color: #f3f6f8;
}
.testimonials .testimonial-tab {
  margin-top: 40px;
}
.testimonials .testimonial-tab .testimonials-tab-list {
  text-align: center;
  border-bottom: 1px solid #e7e9e4;
}
.testimonials .testimonial-tab .testimonials-tab-list li {
  display: inline-block;
  margin: 0 10px;
  padding-bottom: 30px;
}
.testimonials .testimonial-tab .testimonials-tab-list li img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  width: 74px;
  height: 74px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.testimonials .testimonial-tab .testimonials-tab-list li.active img,
.testimonials .testimonial-tab .testimonials-tab-list li:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.testimonials .testimonial-tab .testimonials-tab-list li.active {
  background: url(../images/testimonial-active-bg.jpg) repeat-y center bottom;
}
.testimonials .testimonial-tab .testimonials-tab-content {
  margin: 30px 0;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.testimonials .testimonial-tab .testimonials-tab-content div {
  position: absolute;
  width: 100%;
}
.testimonials .testimonial-tab .testimonials-tab-content div.active {
  position: relative;
}
.testimonials .testimonial-tab .testimonials-tab-content i {
  position: absolute;
  font-size: 60px;
  color: #d9e1e6;
}
.testimonials .testimonial-tab .testimonials-tab-content i.left-quote {
  left: 0;
  top: 85px;
}
.testimonials .testimonial-tab .testimonials-tab-content i.right-quote {
  right: 0;
  bottom: 0;
}
.testimonials .testimonial-tab .testimonials-tab-content h3 {
  color: #00b2ce;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}
.testimonials .testimonial-tab .testimonials-tab-content h3 span {
  color: #b5c2ca;
}
.testimonials .testimonial-tab .testimonials-tab-content p {
  font-size: 18px;
  margin-top: 20px;
  line-height: 36px;
  color: #849aa8;
}
@media (max-width: 767px) {
  .testimonials {
    padding: 50px 0;
  }
  .testimonials .testimonial-tab .testimonials-tab-content {
    margin: 10px 0;
  }
  .testimonials .testimonial-tab .testimonials-tab-content i {
    font-size: 40px;
  }
  .testimonials .testimonial-tab .testimonials-tab-content i.left-quote {
    top: 60px;
  }
}
@media (max-width: 590px) {
  .testimonials {
    padding: 50px 0;
  }
  .testimonials .testimonial-tab .testimonials-tab-list li img {
    width: 65px;
    height: 65px;
  }
  .testimonials .testimonial-tab .testimonials-tab-content {
    margin: 10px 0;
  }
  .testimonials .testimonial-tab .testimonials-tab-content i {
    font-size: 40px;
  }
  .testimonials .testimonial-tab .testimonials-tab-content i.left-quote {
    top: 60px;
  }
}
@media (max-width: 428px) {
  .testimonials {
    padding: 50px 0;
  }
  .testimonials .testimonial-tab .testimonials-tab-list li {
    margin: 0 2px;
  }
  .testimonials .testimonial-tab .testimonials-tab-list li img {
    width: 50px;
    height: 50px;
  }
  .testimonials .testimonial-tab .testimonials-tab-content {
    margin: 20px 0;
  }
  .testimonials .testimonial-tab .testimonials-tab-content i {
    font-size: 30px;
  }
  .testimonials .testimonial-tab .testimonials-tab-content i.left-quote {
    top: 60px;
  }
}
.recent-news {
  padding: 70px 0;
}
.recent-news .news-sec-box {
  margin-top: 50px;
  padding: 15px;
}
.recent-news .news-sec-box h3 {
  font-size: 21px;
  color: #304254;
  font-weight: 400;
}
.recent-news .news-sec-box h3 a {
  color: #304254;
}
.recent-news .news-sec-box h3 a:hover {
  color: #304254;
}
.recent-news .news-sec-box span {
  line-height: normal;
  font-size: 30px;
  color: #00b2ce;
}
.recent-news .news-sec-box p {
  font-weight: 300;
  font-size: 14px;
  color: #989898;
}
.recent-news .news-sec-box .news-by {
  margin-top: 20px;
  font-weight: 300;
  font-size: 14px;
}
.recent-news .news-sec-box .news-by img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  vertical-align: middle;
}
.recent-news .news-sec-box .news-by a {
  color: #00b2ce;
}
.recent-news .news-sec-box:hover {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  background-color: #f3f6f8;
}
.partners .parner-slider-mn {
  border: 1px solid #ededed;
  border-bottom: 0;
  border-right: 0;
}
.partners .col-sm-3 {
  border-bottom: 1px solid #ededed;
}
.partners h2 {
  margin: 0;
  padding: 50px 40px;
}
.partners .col-sm-9 {
  border-left: 1px solid #ededed;
  padding: 0;
}
.partners .partner-slider .owl-pagination {
  display: none;
}
.partners .partner-slider .owl-buttons {
  position: absolute;
  bottom: 20px;
  left: -70px;
}
.partners .partner-slider .partner-logo {
  display: table;
  width: 100%;
  height: 84px;
  text-align: center;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
}
.partners .partner-slider .partner-logo p {
  display: table-cell;
  vertical-align: middle;
  max-height: 44px;
}
@media (min-width: 981px) and (max-width: 1169px) {
  .partners h2 {
    padding: 50px 30px;
  }
}
@media (min-width: 768px) and (max-width: 980px) {
  .partners h2 {
    padding: 50px 0;
  }
  .partners .col-sm-9 {
    border-right: 1px solid #ededed;
  }
  .partners .partner-slider .owl-buttons {
    bottom: 10px;
  }
}
@media (max-width: 767px) {
  .partners h2 {
    padding: 50px 0;
  }
  .partners .partner-slider .owl-buttons {
    bottom: auto;
    left: auto;
    right: 20px;
    top: -30px;
  }
}
.error404 {
  margin: 100px 0;
}
.error404 .page-error-box {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-color: #00b2ce;
  width: 100%;
  margin: 0 auto;
  max-width: 397px;
  position: relative;
  color: #fff;
  text-align: center;
  font-size: 150px;
  line-height: 360px;
  font-weight: 300;
}
.error404 .page-error-box span {
  position: absolute;
  right: 15px;
  top: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-color: #304254;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 24px;
  color: #fff;
}
.error404 .error-txt {
  margin-top: 60px;
}
.error404 .error-txt p {
  font-size: 18px;
  color: #00b2ce;
  margin: 0;
}
.error404 .error-txt h2 {
  line-height: 48px;
  color: #304254;
  margin: 25px 0;
}
@media (max-width: 980px) {
  .error404 .page-error-box {
    font-size: 120px;
    line-height: 300px;
  }
  .error404 .page-error-box span {
    right: 0;
    top: 35px;
  }
  .error404 .error-txt h2 {
    font-size: 26px !important;
  }
}
.service-listing h2 {
  margin-bottom: 50px;
}
.service-listing .last-row {
  border-top: 1px solid #eef1f3;
}
.service-listing .services-dtl {
  text-align: center;
  border-right: 1px solid #eef1f3;
  margin-top: 0;
  padding-top: 60px;
  padding-bottom: 20px;
}
.service-listing .services-dtl span {
  border-bottom: 2px solid #00b2ce;
  padding-bottom: 20px;
  display: inline-block;
}
.service-listing .services-dtl a {
  visibility: hidden;
  display: inline-block;
  margin-top: 15px;
  border: 1px solid #344659;
  line-height: 42px;
  padding: 0 25px;
  border-radius: 21px;
  -webkit-border-radius: 21px;
  -moz-border-radius: 21px;
  color: #344659;
}
.service-listing .services-dtl a:hover {
  border-color: #00b2ce;
  color: #00b2ce;
}
.service-listing .services-dtl:hover h3 {
  color: #00b2ce;
  font-weight: 700;
}
.service-listing .services-dtl:hover a {
  visibility: visible;
}
.service-listing .services-dtl:last-child {
  border-right: none;
}
@media (max-width: 640px) {
  .service-listing .last-row {
    border-top: none;
  }
  .service-listing .services-dtl a {
    visibility: visible;
  }
}
.testimonial-box {
  margin: 80px 0;
}
.testimonial-box .testimonial-box-con {
  border: 1px solid #f3f3f3;
  box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -o-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  padding: 30px;
  margin-top: 50px;
}
.testimonial-box .testimonials-by img {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  display: inline-block;
  margin-right: 25px;
  vertical-align: middle;
}
.testimonial-box .testimonials-by h3 {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  color: #00b2ce;
  font-size: 18px;
  margin: 0;
  line-height: 26px;
}
.testimonial-box .testimonials-by h3 span {
  font-size: 14px;
  color: #b5c2ca;
}
.testimonial-box p {
  margin-top: 20px;
  color: #849aa8;
  font-size: 18px;
  line-height: 36px;
  position: relative;
}
.testimonial-box p span {
  color: #d9e1e6;
  font-size: 20px;
  display: inline-block;
  margin: 0 10px;
}
.testimonial-box li {
  display: inline-block;
  margin-right: 10px;
  font-size: 16px;
}
.testimonial-box li a {
  color: #304254;
}
.testimonial-box li a:hover {
  color: #00b2ce;
}
@media (min-width: 768px) and (max-width: 980px) {
  .testimonial-box .testimonial-box-con {
    padding: 30px 25px;
  }
}
.agent-listing .agent-intro {
  margin-top: 50px;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  color: #304254;
}
.agent-listing a.agent-img {
  background-color: #000;
  display: inline-block;
}
.agent-listing a.agent-img:hover img {
  opacity: 0.8;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.agent-listing .col-sm-6 {
  margin-top: 50px;
}
.agent-listing i {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-color: #00b2ce;
  width: 42px;
  height: 42px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  display: inline-block;
  margin-top: 25px;
}
.agent-listing h3 {
  font-size: 16px;
  color: #304254;
}
.agent-listing h3 a {
  color: #304254;
}
.agent-listing h3 span {
  font-size: 12px;
  color: #00b2ce;
}
.agent-listing p {
  color: #989898;
  line-height: 25px;
  padding-top: 10px;
}
@media (min-width: 481px) and (max-width: 640px) {
  .agent-listing .col-sm-6 {
    float: left;
    width: 50%;
  }
}
.columns {
  margin: 50px 0;
}
.columns h3 {
  font-size: 18px;
  color: #304254;
  margin: 0;
}
.columns h3 span {
  color: #00b2ce;
}
.columns p {
  line-height: 30px;
  color: #989898;
  margin-top: 20px;
  font-size: 14px;
}
.columns .row div {
  margin-top: 50px;
}
.accordion-section {
  overflow: hidden;
  border: 1px solid #f3f3f3;
  box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -o-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  background: #fff;
  margin-top: 30px;
}
.accordion-section .accordion-section-title {
  width: 100%;
  padding: 25px 40px;
  display: inline-block;
  transition: all linear 0.15s;
  -webkit-transition: all linear 0.15s;
  -moz-transition: all linear 0.15s;
  -o-transition: all linear 0.15s;
  font-size: 16px;
  color: #304254;
  position: relative;
}
.accordion-section .accordion-section-title i {
  position: absolute;
  right: 30px;
  top: 25px;
  color: #d3d3d3;
  font-size: 24px;
  transition: all ease 0.15s;
  -webkit-transition: all ease 0.15s;
  -moz-transition: all ease 0.15s;
  -o-transition: all ease 0.15s;
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}
.accordion-section .accordion-section-title.active,
.accordion-section .accordion-section-title:focus,
.accordion-section .accordion-section-title:hover {
  text-decoration: none;
  outline: none;
}
.accordion-section .accordion-section-title.active i,
.accordion-section .accordion-section-title:focus i,
.accordion-section .accordion-section-title:hover i {
  color: #00b2ce;
}
.accordion-section .accordion-section-title.active i {
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}
.accordion-section .accordion-section-content {
  padding: 0 30px 25px;
  display: none;
}
.accordion-section .accordion-section-content p {
  color: #989898;
  font-size: 14px;
  line-height: 30px;
}
@media (max-width: 980px) {
  .accordion-section .accordion-section-title i {
    right: 15px;
  }
}
.faq-sec {
  border: 1px solid #f3f3f3;
  box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -o-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  background: #fff;
  margin-top: 60px;
  padding: 0 30px 30px;
}
.faq-sec .ques {
  width: 40px;
  display: inline-block;
  background-color: #00b2ce;
  line-height: 40px;
  padding-top: 30px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  border-radius: 0 0 20px 20px;
  -webkit-border-radius: 0 0 20px 20px;
  -moz-border-radius: 0 0 20px 20px;
  vertical-align: top;
  font-size: 20px;
}
.faq-sec .faq-con {
  display: inline-block;
  vertical-align: top;
  margin-left: 30px;
  width: 80%;
}
.faq-sec .faq-con h3 {
  font-size: 16px;
  color: #304254;
  margin-top: 30px;
}
.faq-sec .faq-con p {
  color: #989898;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 30px;
}
@media (min-width: 768px) and (max-width: 980px) {
  .faq-sec .faq-con {
    width: 73%;
  }
}
@media (max-width: 428px) {
  .faq-sec .faq-con {
    width: 100%;
    margin: 0;
  }
}
.find-agent {
  margin: 50px 0 100px;
}
.find-agent .find-agent-con {
  border: 1px solid #f3f3f3;
  box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -o-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  background: #fff;
  margin-top: 60px;
  padding: 0 30px;
}
.find-agent .find-agent-con .find-agent-form {
  padding: 50px 15px 50px 0;
}
.find-agent .find-agent-con .find-agent-form input[type="submit"] {
  margin-top: 30px;
}
.find-agent .find-agent-con .find-agent-form p {
  margin-top: 30px;
  font-size: 12px;
  font-weight: 300;
  color: #304254;
}
.find-agent .find-agent-con .find-agent-feat {
  padding: 30px 0 50px 30px;
  border-left: 1px solid #f3f3f3;
}
.find-agent .find-agent-con .find-agent-feat li {
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  padding-top: 10px;
  color: #869294;
  font-weight: 300;
}
.find-agent .find-agent-con .find-agent-feat li:before {
  position: absolute;
  left: 0;
  top: 10px;
  font-size: 16px;
  content: "";
  color: #00b2ce;
  font-family: FontAwesome;
}
@media (max-width: 767px) {
  .find-agent .find-agent-con .find-agent-feat {
    padding: 0 0 50px 0;
    border-left: none;
  }
}
.contact-us {
  margin: 70px 0;
}
.contact-us .contact-map {
  display: block;
}
.form-box {
  border: 1px solid #f3f3f3;
  border-top: none;
  box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -o-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  background: #fff;
}
.form-box h2 {
  margin: 0;
  color: #fff !important;
  background-color: #00b2ce;
  line-height: 65px;
  padding: 0 25px;
}
.form-box .form-content {
  padding: 25px;
}
.form-box .form-content input[type="text"],
.form-box .form-content textarea {
  margin-bottom: 25px;
}
.form-box .form-content input[type="submit"] {
  margin: 25px 0;
}
.contact-info h3 {
  font-size: 24px;
  color: #304254;
  font-weight: 300;
  margin: 0;
}
.contact-info i.icon {
  color: #fff;
  background-color: #00b2ce;
  text-align: center;
  line-height: 37px;
  width: 37px;
  margin-right: 15px;
  border-radius: 50%;
  webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
}
.contact-info p {
  vertical-align: top;
  display: inline-block;
  width: 82%;
  font-size: 16px;
  line-height: 32px;
  color: #768ba2;
  font-weight: 300;
}
.contact-info p a {
  color: #768ba2;
}
.contact-info p a:hover {
  color: #00b2ce;
}
.contact-info p.social {
  margin-left: 55px;
}
.contact-info p.social a {
  display: inline-block;
  color: #304254;
  margin-right: 5px;
  font-size: 16px;
  vertical-align: middle;
}
.contact-info p.social a:hover {
  color: #00b2ce;
}
.contact-info .contact-address {
  padding: 50px 30px;
  border-right: 1px solid #f3f3f3;
}
.contact-info .contact-address div {
  margin-top: 20px;
}
.contact-info .contact-dtl {
  padding: 50px 30px;
}
.contact-info .contact-dtl div {
  margin-top: 20px;
}
@media (min-width: 981px) and (max-width: 1169px) {
  .contact-info p {
    width: 77%;
    font-size: 15px;
    line-height: 30px;
  }
  .contact-info .contact-address {
    padding: 50px 0;
  }
}
@media (min-width: 768px) and (max-width: 980px) {
  .contact-info .contact-address {
    padding: 50px 10px 50px 30px;
  }
  .contact-info .contact-dtl {
    padding: 50px 10px 50px 30px;
  }
}
@media (max-width: 767px) {
  .contact-info p {
    width: 80%;
    font-size: 15px;
  }
  .contact-info .contact-address {
    padding: 50px 0;
    border: none;
  }
  .contact-info .contact-dtl {
    padding: 0 0 50px;
  }
}
.agent-listings {
  margin: 100px 0;
}
.agent-listings .agent-dtl-con {
  border: 1px solid #f3f3f3;
  border-left: none;
  border-bottom: none;
  box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  -o-box-shadow: 0 -14px 35px 0 rgba(0, 0, 0, 0.03);
  background: #fff;
  margin-left: -30px;
}
.agent-listings .agent-intro {
  padding: 40px;
}
.agent-listings .agent-intro h3 {
  font-size: 24px;
  color: #304254;
  font-weight: 300;
  margin: 0;
}
.agent-listings .agent-intro h3 span {
  color: #00b2ce;
  font-size: 12px;
  font-weight: 300;
}
.agent-listings .agent-intro article {
  font-size: 16px;
  line-height: 30px;
  margin: 20px 0;
  color: #304254;
  font-weight: 300;
}
.agent-listings .agent-intro p {
  font-weight: 300;
  color: #989898;
  line-height: 25px;
}
.agent-listings .contact-info {
  background-color: #f6f8f9;
}
.agent-listings .form-box {
  margin-left: -30px;
}
.agent-listings .form-box input[type="text"].input-inline {
  width: 48%;
  display: inline-block;
}
.agent-listings .form-box input[type="text"].input-inline.email {
  margin-right: 1%;
}
.agent-listings .form-box input[type="text"].input-inline.phone {
  margin-left: 2%;
}
@media (min-width: 981px) and (max-width: 1169px) {
  .agent-listings .contact-info .contact-address {
    padding-left: 15px;
    padding-right: 15px;
  }
  .agent-listings .contact-info p {
    font-size: 14px;
  }
}
@media (max-width: 980px) {
  .agent-listings .agent-dtl-con,
  .agent-listings .form-box {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .agent-listings .form-box input[type="text"] {
    width: 100% !important;
    margin: 0 !important;
    display: block !important;
  }
}
.product-main {
  background-color: #e7edee;
  position: relative;
  z-index: 10;
  overflow: hidden;
  margin-top: -20px;
}
.product-main .prod-tab-content {
  padding-bottom: 60px;
}
.product-main h2 {
  margin-top: 50px;
  margin-bottom: 30px;
}
.product-main h4 {
  font-size: 24px;
  color: #304254;
  font-weight: 300;
  margin: 0;
}
.product-main h4 span {
  background-color: #00b2ce;
  margin-right: 15px;
  margin-top: -25px;
  text-align: center;
  width: 50px;
  display: inline-block;
  padding: 50px 0 20px;
  border-radius: 0 0 25px 25px;
  -moz-border-radius: 0 0 25px 25px;
  -webkit-border-radius: 0 0 25px 25px;
  color: #fff;
  font-size: 24px;
}
.product-main p {
  font-weight: 300;
  font-size: 14px;
  color: #869294;
  line-height: 30px;
  margin-top: 30px;
}
.product-main p.tel {
  font-size: 20px;
}
.product-main p.tel span {
  font-size: 12px;
  display: block;
}
.product-main img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  max-width: 675px;
  width: 100%;
}
.product-detail h2 {
  margin: 70px 0 30px;
}
.product-detail h4 {
  color: #304254;
  font-size: 16px;
}
.product-detail p {
  color: #989898;
  font-size: 14px;
}
.product-detail ul li {
  color: #304254;
  font-size: 16px;
  line-height: 35px;
}
.product-detail ul li span {
  color: #00b2ce;
  margin-right: 10px;
}
.product-detail .benifit {
  -webkit-box-shadow: 0 2px 18px rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.07);
  padding: 60px 75px;
}
.wwa {
  text-align: center;
}
.wwa h2 {
  margin: 70px 0 30px;
}
.wwa h4 {
  color: #304254;
  font-size: 16px;
}
.wwa p {
  color: #989898;
  font-size: 14px;
  margin-bottom: 50px;
}
.wwa .mis-vis {
  -webkit-box-shadow: 0 2px 18px rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.07);
  padding: 50px 75px 30px;
}
.wwa .mis-vis span {
  display: inline-block;
  height: 53px;
  width: 53px;
  border-radius: 100%;
  background: #00b2ce;
  line-height: 53px;
  text-align: center;
  color: #fff;
  font-size: 24px;
}
.wwa .mis-vis h4 {
  color: #304254;
  font-size: 16px;
  margin: 30px 0;
}
@media (min-width: 1920px) {
  .product-main img {
    max-width: 960px;
  }
}
@media (min-width: 768px) and (max-width: 980px) {
  .product-main h4 span {
    margin-top: 0;
  }
  .product-main img {
    position: relative;
    z-index: 1;
    margin-top: 30px;
    float: right;
    width: 35%;
  }
}
@media (max-width: 767px) {
  .product-main h4 span {
    margin-top: 0;
  }
  .product-main img {
    position: relative;
    z-index: 1;
    margin-top: 20px;
    width: 100%;
  }
}
.blog-page {
  margin-top: 50px;
}
.blog-page .single-post {
  margin-top: 50px;
}
.blog-page .single-post .blog-icon {
  margin-top: 35px;
}
.blog-page .single-post .blog-meta {
  color: #a6a6a6;
}
.blog-page .single-post .blog-meta a {
  margin-left: 30px;
  color: #a6a6a6;
}
.blog-page .single-post .blog-meta a:hover {
  color: #00b2ce;
}
.blog-page .single-post .blog-meta a i {
  margin-right: 10px;
}
.blog-page .single-post p {
  color: #a6a6a6;
  font-weight: 300;
  font-size: 15px;
  margin-top: 15px;
  line-height: 25px;
}
.blog-page .single-post .blog-btn {
  margin-top: 30px;
}
.blog-page .single-post .blog-btn .img-inline {
  float: right;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.blog-page .single-post .blog-btn .img-inline img {
  border-radius: 100%;
  width: 42px;
  height: 42px;
  margin-right: 20px;
}
.blog-page .single-post .blog-btn .img-inline a {
  color: #00b2ce;
  margin-top: 10px;
}
.quote-post {
  margin-top: 50px;
  padding: 40px;
  background-color: #efefef;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.quote-post .quote-img {
  margin-right: 30px;
}
.quote-post .quote-text h5 {
  margin: 0;
  line-height: 35px;
  color: #a6a6a6;
  font-family: Georgia;
  font-style: italic;
  font-size: 16px;
}
.quote-post .quote-text h6 {
  color: #00b2ce;
  font-family: Georgia;
  font-style: italic;
  font-size: 16px;
}
.ins-page {
  margin: 40px 0;
}
.ins-page li {
  text-transform: uppercase;
}
.ins-page li:first-child span {
  background: #00b2ce;
  color: #fff;
  border-color: #00b2ce;
  border-radius: 20px;
  margin-right: 10px;
}
.ins-page li:first-child span:hover {
  background: #fff;
  color: #00b2ce;
  border-color: #00b2ce;
}
.ins-page li:last-child a {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.ins-page li a {
  background: #efefef;
  color: #000;
  border-color: #efefef;
  border-radius: 20px;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .ins-page {
    font-size: 12px;
  }
  .quote-post {
    display: inline-block;
    padding: 30px;
  }
  .quote-post .quote-img {
    margin-bottom: 25px;
  }
  .blog-title {
    font-size: 24px !important;
  }
}
.blog-post {
  margin-top: 50px;
}
.blog-post h2 {
  font-size: 24px !important;
  margin-top: 40px;
  margin-bottom: 35px;
}
.blog-post .search-text .search {
  padding: 15px;
  background-color: #efefef;
}
.blog-post .search-text .btn-search {
  max-height: 40px;
  padding: 5px 22px;
  line-height: normal;
}
.category-post .inline-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 15px;
  font-size: 15px;
}
.category-post .inline-text h4 {
  margin: 0;
  margin-left: 15px;
  font-size: 15px;
  color: #304254;
}
.category-post .inline-text h4:hover {
  color: #00b2ce;
}
.category-post .inline-text .margin-left-auto {
  margin-left: auto;
}
.blue-text {
  color: #00b2ce;
}
.border-bottom {
  border-bottom: 1px solid #efefef;
}
.recent-post li a {
  font-size: 14px;
  color: #304254;
}
.recent-post li a:hover {
  color: #00b2ce;
}
.recent-post li h6 {
  color: #a6a6a6;
}
.tags a {
  padding: 8px 15px;
  background: #f9f9f9;
  color: #a4a4a4;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
  border-radius: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 11px;
}
.tags a:first-child,
.tags a:hover {
  background: #00b2ce;
  color: #fff;
}
.blog-author {
  margin-top: 35px;
}
.blog-author .author-text {
  background-color: #00b2ce;
  color: #fff;
  padding: 15px;
  text-align: center;
  min-width: 263px;
  font-size: 14px;
  text-transform: uppercase;
}
.blog-author .cap-title {
  line-height: 26px;
  color: #304254;
  margin-top: 25px;
}
.caption-list {
  margin-top: 25px;
}
.caption-list li {
  margin-bottom: 10px;
}
.caption-list li a {
  margin-left: 10px;
  color: #a6a6a6;
}
.caption-list li a:hover {
  color: #00b2ce;
}
.quote-post1 {
  margin: 35px 0;
  padding: 40px;
  background-color: #efefef;
  border-left: 3px solid #00b2ce;
}
.quote-post1 .quote-text h5 {
  margin: 0;
  line-height: 35px;
  color: #a6a6a6;
  font-family: Georgia;
  font-style: italic;
  font-size: 16px;
}
.display-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.display-line .ml-auto {
  margin-left: auto;
}
.relate-post h6 {
  font-size: 15px;
  color: #304254;
}
.relate-post a {
  font-size: 15px;
}
.relate-post a:hover {
  color: #00b2ce;
}
.social-post {
  margin: 45px 0;
  padding: 40px;
  background-color: #f1f6f8;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.social-post .social-img {
  margin-right: 30px;
  max-width: 100px;
  border-radius: 100%;
}
.social-post .social-text h5 {
  line-height: 35px;
  color: #a6a6a6;
  font-family: Georgia;
  font-style: italic;
  font-size: 16px;
}
.social-post .social-text h6 {
  color: #00b2ce;
  font-family: Georgia;
  font-style: italic;
  font-size: 16px;
}
.social-post .social-icon li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 5px;
}
.social-post .social-icon a {
  background: #d6e4ea;
  border-radius: 100%;
  padding: 10px 15px;
  color: #a6a6a6;
}
.social-post .social-icon a:hover {
  background: #66cef6;
  color: #fff;
}
.comment-post {
  margin: 45px 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.comment-post .comment-img {
  margin-right: 30px;
  max-width: 100px;
  border-radius: 100%;
}
.comment-post .comment-text h4 {
  color: #304254;
}
.comment-post .comment-text h5 {
  line-height: 28px;
  color: #a6a6a6;
  font-size: 16px;
}
.comment-post .comment-text h6 {
  color: #00b2ce;
  font-size: 16px;
}
.comment-post-right {
  margin-left: 127px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 30px;
  background: #f1f6f8;
}
.comment-post-right .comment-img {
  margin-right: 30px;
  max-width: 100px;
  border-radius: 100%;
}
.comment-post-right .comment-text h4 {
  color: #304254;
}
.comment-post-right .comment-text h5 {
  line-height: 28px;
  color: #a6a6a6;
  font-size: 16px;
}
.comment-post-right .comment-text h6 {
  color: #00b2ce;
  font-size: 16px;
}
.comment {
  margin-top: 35px;
}
.comment input[type="password"],
.comment input[type="text"],
.comment select,
.comment textarea {
  background-color: #f9f9f9;
  border-bottom: none;
  padding: 12px;
  border-radius: 20px;
}
@media (max-width: 767px) {
  .blog-page .single-post .blog-meta a i {
    margin-right: 2px;
  }
  .sm-display {
    display: inherit;
  }
  .social-post {
    display: inline-block;
    padding: 30px;
    text-align: center;
  }
  .sm-inline {
    display: inherit;
  }
  .comment-post {
    display: inherit;
  }
  .comment-post-right {
    display: inherit;
    margin-left: 0;
  }
}
